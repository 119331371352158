import React from "react";

const SkillContent = ({ content }) => {
  return (
    <div className="skill__content">
      <div>
        <i className={`uil uil-${content.icon} skill__icon`}></i>
        <h3 className="skill__content-title">{content.title}</h3>
      </div>
      <ul className="skill__content-items grid">
        {content.items.map((item, index) => (
          <li key={index} className="skill__content-item">
            <i className="uil uil-check-circle" />
            <p>{item}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SkillContent;
