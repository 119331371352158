import emailjs from "@emailjs/browser";
import React, { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";

import "./ContactSection.css";

const ContactSection = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (
      form.current.name.value &&
      form.current.email.value &&
      form.current.project.value
    ) {
      emailjs
        .sendForm(
          "service_dsz2tan",
          "template_2pgs4wf",
          form.current,
          "Exq3YjRZdzH-P7hNW"
        )
        .then((_) => {
          toast("👍 Sent Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          form.current.name.value = "";
          form.current.email.value = "";
          form.current.project.value = "";
        });
    }
  };

  return (
    <section className="contact section" id="contact">
      <h1 className="section__title">Contact</h1>
      <span className="section__subtitle">Get In Touch</span>

      <div className="contact__container container grid">
        <div className="contact__content">
          <h3 className="contact__title">Talk to me</h3>
          <div className="contact__info">
            <div className="contact__card">
              <i className="uil uil-envelope contact__card-icon"></i>

              <h3 className="contact__card-title">Email</h3>
              <span className="contact__card-data">
                haonguyen2dev@gmail.com
              </span>
            </div>

            <div className="contact__card">
              <i className="uil uil-phone contact__card-icon"></i>

              <h3 className="contact__card-title">Phone</h3>
              <span className="contact__card-data">(+84)967438901</span>
            </div>
          </div>
        </div>

        <div className="contact__content">
          <h3 className="contact__title">Write me your project</h3>

          <form ref={form} onSubmit={sendEmail} className="contact__form">
            <div className="contact__form-unit">
              <label htmlFor="" className="contact__form-tag">
                Name
              </label>
              <input
                type="text"
                name="name"
                className="contact__form-input"
                placeholder="Your name"
              />
            </div>

            <div className="contact__form-unit">
              <label htmlFor="" className="contact__form-tag">
                Email
              </label>
              <input
                type="email"
                name="email"
                className="contact__form-input"
                placeholder="Your Email"
              />
            </div>

            <div className="contact__form-unit contact__form-area">
              <label htmlFor="" className="contact__form-tag">
                Project
              </label>
              <textarea
                name="project"
                cols="30"
                rows="10"
                className="contact__form-input"
                placeholder="Your project"
              />
            </div>
            <button className="button button--flex">
              Send
              <i className="uil uil-message button-icon" />
            </button>
          </form>
        </div>
      </div>

      <ToastContainer />
    </section>
  );
};

export default ContactSection;
