import React, { useState } from "react";
import "./Header.css";
import NavItemModel from "../../models/nav_item";

const navItems = [
  new NavItemModel("Experiences", "award", "#experience"),
  new NavItemModel("Portfolio", "scenery", "#portfolio"),
  new NavItemModel("Skills", "file-alt", "#skill"),
  new NavItemModel("Contact", "message", "#contact"),
];

const Header = () => {
  const [isVisibleMenu, showMenu] = useState(false);
  window.addEventListener("scroll", function () {
    const headerElement = this.document.querySelector("#header");
    if (this.scrollY >= 80) headerElement.classList.add("header-scroll");
    else headerElement.classList.remove("header-scroll");
  });
  return (
    <header className="header" id="header">
      <nav className="nav container">
        <a href="#home" className="nav__logo">
          haonguyen2dev
        </a>

        <div className={isVisibleMenu ? "nav__menu show-menu" : "nav__menu"}>
          <ul className="nav__list grid">
            {navItems.map((item, index) => (
              <li key={index} className="nav__item">
                <a href={item.link} className="nav__item-link">
                  <i className={`uil uil-${item.icon} nav__item-icon`} />
                  {item.title}
                </a>
              </li>
            ))}
          </ul>

          <i
            className="uil uil-times nav__close"
            onClick={() => showMenu(false)}
          />
        </div>

        <div className="nav__toggle" onClick={() => showMenu(true)}>
          <i className="uil uil-apps"></i>
        </div>
      </nav>
    </header>
  );
};

export default Header;
