import React from "react";
import "./HomeSection.css";

import HomeData from "./components/HomeData";
import HomeSocial from "./components/HomeSocial";
import HomeScrollDown from "./components/HomeScrollDown";

const HomeSection = () => {
  return (
    <section className="home section" id="home">
      <div className="home__container container grid">
        <div className="home__content grid">
          <HomeSocial />
          <div className="home__avatar"></div>
          <HomeData />
        </div>

        <HomeScrollDown />
      </div>
    </section>
  );
};

export default HomeSection;
