import React from "react";
import "./SkillSection.css";
import SkillContentModel from "../../models/skill_content";
import SkillContent from "./components/SkillContent";

const skillContents = [
  new SkillContentModel("Mobile Developer", "mobile-android", [
    "Cross-platform framework: Flutter (Dart)",
    "Build and publish apps to the store",
    "Knowledge of native programming languages",
    "Knowledge of HTML, JavaScript, CSS",
    "Knowledge of CI/CD",
  ]),
  new SkillContentModel("Backend Developer", "arrow", [
    "Framework/Library: NodeJS, ExpressJS, NestJS",
    "Language: Javascript, TypeScript",
    "Database: MongoDB, PostgreSQL, Firestore",
    "Knowledge of Firebase, Google Cloud Platform",
    "Knowledge of web3, blockchain",
  ]),
  new SkillContentModel("Others", "pen", [
    "Clean & modular code",
    "Version control: Git (Github, Gitlab)",
    "Tasks control: Notion",
    "Agile development methodology",
    "Problem-solving and responsibility spirit",
    "English capability",
  ]),
];

const SkillSection = () => {
  return (
    <section className="skill section" id="skill">
      <h1 className="section__title">Skills</h1>
      <span className="section__subtitle">My Technical</span>

      <div className="skill__container container grid">
        {skillContents.map((item, index) => (
          <SkillContent key={index} content={item} />
        ))}
      </div>
    </section>
  );
};

export default SkillSection;
