import React, { useState } from "react";

const PortfolioSliderOverlay = ({ items, initialSlide, onClose }) => {
  const [currentSlide, setCurrentSlide] = useState(initialSlide);

  function handlePrev() {
    setCurrentSlide((currentSlide - 1 + items.length) % items.length);
  }

  function handleNext() {
    setCurrentSlide((currentSlide + 1) % items.length);
  }

  return (
    <div className="portfolio__slider-overlay">
      <i
        class="uil uil-angle-left-b portfolio__slider-prev"
        onClick={handlePrev}
      ></i>

      <img src={items[currentSlide].image} alt="" />

      <i
        class="uil uil-angle-right-b portfolio__slider-next"
        onClick={handleNext}
      ></i>

      <i class="uil uil-multiply portfolio__slider-close" onClick={onClose}></i>
    </div>
  );
};

export default PortfolioSliderOverlay;
