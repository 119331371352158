import React from "react";
import "./ScrollUpButton.css";

const ScrollUpButton = () => {
  window.addEventListener("scroll", function () {
    const scrollUpElement = this.document.querySelector("#scrollup");
    if (this.scrollY >= 560) scrollUpElement.classList.add("visible");
    else scrollUpElement.classList.remove("visible");
  });
  return (
    <a href="#home" className="scrollup" id="scrollup">
      <i className="uil uil-arrow-up scrollup__icon"></i>
    </a>
  );
};

export default ScrollUpButton;
