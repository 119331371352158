import React from "react";

const HomeSocial = () => {
  return (
    <div className="home__social">
      <a
        href="https://github.com/haonguyenuet"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <i className="uil uil-github-alt"></i>
      </a>

      <a
        href="https://linkedin.com/in/twilightnguyen"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <i className="uil uil-linkedin-alt"></i>
      </a>

      <a
        href="https://discord.com/users/1011469623268491384"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <i className="uil uil-discord"></i>
      </a>
    </div>
  );
};

export default HomeSocial;
