import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <span>Let do it, if you want. It may take sometime.</span>
      </div>
    </footer>
  );
};

export default Footer;
