import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import ContactSection from './components/contact/ContactSection';
import ExperienceSection from './components/experiences/ExperienceSection';
import Header from './components/header/Header';
import HomeSection from './components/home/HomeSection';
import PortfolioSection from './components/portfolio/PortfolioSection';
import SkillSection from './components/skill/SkillSection';
import Footer from './components/footer/Footer';
import ScrollUpButton from './components/scroll-up/ScrollUpButton';

function App() {
  return (
    <>
      <Header />

      <main className='main'>
        <HomeSection />
        <ExperienceSection />
        <PortfolioSection />
        <SkillSection />
        <ContactSection />
      </main>

      <Footer />
      <ScrollUpButton />
    </>
  );
}

export default App;
