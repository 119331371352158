import React from "react";

const PortfolioItem = ({ item, onImageClicked }) => {
  return (
    <div className="portfolio__item">
      <div className="portfolio__item-image" onClick={onImageClicked}>
        <img src={item.image} alt="" />
      </div>
      <h3 className="portfolio__item-name">{item.name}</h3>
      {item.iosUrl || item.androidUrl || item.websiteUrl ? (
        <div className="portfolio__item-links">
          {item.iosUrl && item.iosUrl !== "" ? (
            <a href={item.iosUrl} className="portfolio__item-link">
              App Store
            </a>
          ) : null}
          {item.androidUrl && item.androidUrl !== "" ? (
            <a href={item.androidUrl} className="portfolio__item-link">
              CHPlay
            </a>
          ) : null}
          {item.websiteUrl && item.websiteUrl !== "" ? (
            <a href={item.websiteUrl} className="portfolio__item-link">
              Website
            </a>
          ) : null}
        </div>
      ) : (
        <span>In development</span>
      )}
    </div>
  );
};

export default PortfolioItem;
